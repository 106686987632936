import React from "react";

type MainProps = {
  backgroundColor?: string;
};

export function Main(props: React.PropsWithChildren<MainProps>) {
  return (
    <div
      id="main"
      tabIndex={-1}
      className="site-main"
      role="main"
      style={{ backgroundColor: props.backgroundColor }}
    >
      {props.children}
    </div>
  );
}
