import * as React from "react";

export const Footer: React.FunctionComponent = () => {
  return (
    <footer id="colophon" className="site-footer" role="contentinfo">
      <h2 className="screen-reader-text">Footer</h2>
      <div className="container-xxl">
        <div className="row footer-top footer-widgets align-items-center">
          <div className="col-12 footer-1">
            <div id="text-2" className="widget widget_text">
              {" "}
              <div className="textwidget">
                <h3>
                  <a
                    href="https://instagram.com/bumble/"
                    target="_blank"
                    rel="noopener"
                  >
                    Follow @Bumble
                  </a>
                </h3>
                <p>
                  <img
                    loading="lazy"
                    decoding="async"
                    className="alignnone size-medium wp-image-13339"
                    src="/the-buzz/logo/ASA-Trend-Of-The-Year-2021.png?w=500"
                    alt="Trend of the Year: Connection 2021"
                    width="500"
                    height="500"
                    srcSet="/the-buzz/logo/ASA-Trend-Of-The-Year-2021.png 500w, /the-buzz/logo/ASA-Trend-Of-The-Year-2021.png?resize=200,200 200w, /the-buzz/logo/ASA-Trend-Of-The-Year-2021.png?resize=150,150 150w, /the-buzz/logo/ASA-Trend-Of-The-Year-2021.png?resize=120,120 120w"
                    sizes="auto, (max-width: 500px) 100vw, 500px"
                  />
                </p>
              </div>
            </div>
            <div id="nav_menu-2" className="widget widget_nav_menu">
              <h3 className="widget-title">Success Stories</h3>
              <div className="menu-success-stories-container">
                <ul id="menu-success-stories" className="menu">
                  <li
                    id="menu-item-13264"
                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-13264"
                  >
                    <a href="#">Highlights</a>
                  </li>
                  <li
                    id="menu-item-13265"
                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-13265"
                  >
                    <a href="#">Submit</a>
                  </li>
                </ul>
              </div>
            </div>{" "}
          </div>
          {/* .col  */}
          <div className="col-sm-6 footer-2-left">
            <img
              src="/the-buzz/logo/bumble-site-logo.svg"
              alt="Bumble Buzz home"
              className="bumble-logo"
            />
          </div>
          {/* .col  */}
          <div className="col-sm-6 footer-2-right">
            <div
              id="social-navigation-2"
              className="social-nav"
              role="presentation"
            >
              <h3 id="social-media-2" className="screen-reader-text">
                Social Media Menu
              </h3>
              <ul
                id="menu-social-menu-1"
                className="yikes-social-menu"
                aria-labelledby="social-media-2"
              >
                <li className="yks-icon yks-icon-instagram menu-item menu-item-type-custom menu-item-object-custom menu-item-13283 nav-item">
                  <a
                    target="_blank"
                    href="https://instagram.com/bumble/"
                    className="nav-link"
                  >
                    <svg>
                      <use xlinkHref="#instagram"></use>
                    </svg>
                    <span className="screen-reader-text">
                      Instagram, opens in new tab
                    </span>
                  </a>
                </li>
                <li className="yks-icon yks-icon-facebook menu-item menu-item-type-custom menu-item-object-custom menu-item-13284 nav-item">
                  <a
                    target="_blank"
                    href="https://www.facebook.com/bumbleapp/"
                    className="nav-link"
                  >
                    <svg>
                      <use xlinkHref="#facebook"></use>
                    </svg>
                    <span className="screen-reader-text">
                      Facebook, opens in new tab
                    </span>
                  </a>
                </li>
                <li className="yks-icon yks-icon-twitter menu-item menu-item-type-custom menu-item-object-custom menu-item-13282 nav-item">
                  <a
                    target="_blank"
                    href="https://twitter.com/bumble"
                    className="nav-link"
                  >
                    <svg>
                      <use xlinkHref="#twitter"></use>
                    </svg>
                    <span className="screen-reader-text">
                      Twitter, opens in new tab
                    </span>
                  </a>
                </li>
                <li className="yks-icon yks-icon-pinterest menu-item menu-item-type-custom menu-item-object-custom menu-item-13347 nav-item">
                  <a
                    target="_blank"
                    href="https://www.pinterest.com/bumbleapp/"
                    className="nav-link"
                  >
                    <svg>
                      <use xlinkHref="#pinterest"></use>
                    </svg>
                    <span className="screen-reader-text">
                      Pinterest, opens in new tab
                    </span>
                  </a>
                </li>
              </ul>{" "}
            </div>
            {/* #social-navigation  */}
          </div>
          {/* .col  */}
        </div>
        {/* .row  */}

        <div className="row footer-bottom">
          <div className="col-12 footer-col">
            <h3
              id="footer-menu"
              className="footer-menu-title screen-reader-text"
            >
              Footer Menu
            </h3>
            <ul
              id="menu-footer-menu"
              className="menu footer-menu"
              aria-labelledby="footer-menu"
            >
              <li
                id="menu-item-13340"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-13340"
              >
                <a href="https://bumble.com/en/help/">FAQ</a>
              </li>
              <li
                id="menu-item-13341"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-13341"
              >
                <a href="https://bumble.events/">Events</a>
              </li>
              <li
                id="menu-item-13268"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-13268"
              >
                <a href="https://bumble.com/en/contact-us">Contact Us</a>
              </li>
              <li
                id="menu-item-13267"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-13267"
              >
                <a href="https://bumble.com/en/guidelines">Guidelines</a>
              </li>
              <li
                id="menu-item-13342"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-13342"
              >
                <a href="http://team.bumble.com/">Careers</a>
              </li>
              <li
                id="menu-item-13275"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-13275"
              >
                <a href="https://ir.bumble.com/">Investors</a>
              </li>
              <li
                id="menu-item-13343"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-13343"
              >
                <a
                  target="_blank"
                  href="https://bumbcdn.com/big/documents/bumble/statement-on-modern-slavery-act.pdf"
                >
                  Modern Slavery Act Statement
                </a>
              </li>
            </ul>{" "}
          </div>
          {/* .col  */}
          <div className="col-12 footer-col">
            <h3
              id="copyright-menu"
              className="footer-menu-title screen-reader-text"
            >
              Legal Links
            </h3>
            <ul
              id="menu-legal-links"
              className="menu footer-menu"
              aria-labelledby="copyright-menu"
            >
              <li
                id="menu-item-13344"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-13344"
              >
                <a href="https://bumble.com/en-us/terms">
                  Terms &amp; Conditions
                </a>
              </li>
              <li
                id="menu-item-13345"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-13345"
              >
                <a href="https://bumble.com/en-us/privacy">Privacy Policy</a>
              </li>
              <li
                id="menu-item-15671"
                className="pmLink menu-item menu-item-type-custom menu-item-object-custom menu-item-15671"
              >
                <a href="#" style={{ visibility: "visible" }}>
                  Privacy Preferences
                </a>
              </li>
            </ul>{" "}
          </div>
          {/* .col  */}
          <div className="col-12 credits">
            <h3 id="copyright-information" className="screen-reader-text">
              Copyright Information
            </h3>
            © 2024{" "}
            <a href="https://bumble-buzz.com/" rel="home">
              Bumble<span className="screen-reader-text"> Home</span>
            </a>{" "}
            | All Rights Reserved
          </div>
          {/* .col  */}
        </div>
        {/* .row  */}
      </div>
      {/* .container  */}
    </footer>
  );
};
