import React from "react";

import { Post, Tag } from "interfaces/wordpress";
import ImageContainer from "lib/image-container";

const lexemes = {
  articleTitleScreenReader: " Article,",
  tagListTItleScreenReader: " Article Tags",
  tagTItleScreenReader: " Tag",
};

export type Props = {
  post?: Post;
  classnames?: string;
  sizes?: string;
};

export default function Card(props: Props) {
  const { post, classnames = "col" } = props;
  return (
    <article className={classnames}>
      <div className="card">
        <div className="card-img-container">
          {post?.featuredImage && post?.featuredImage?.node?.mediaItemUrl ? (
            <ImageContainer
              className="card-img-top wp-post-image"
              alt={post?.featuredImage?.node?.altText || ""}
              src={post?.featuredImage?.node?.mediaItemUrl}
              height={post?.featuredImage?.node?.mediaDetails?.height || 0}
              width={post?.featuredImage?.node?.mediaDetails?.width || 0}
              sizes={props.sizes}
            />
          ) : null}
        </div>
        <div className="card-body">
          <div className="card-top">
            <h3 className="card-title">
              <a className="post-link" href={`/the-buzz/${post?.slug}`}>
                {post?.title}
                <span className="screen-reader-text">
                  {lexemes.articleTitleScreenReader}
                </span>
              </a>
            </h3>
          </div>
          <div className="card-footer">
            <h4 id="tag-list" className="screen-reader-text">
              {lexemes.tagListTItleScreenReader}
            </h4>
            <ul className="tag-list" aria-labelledby="tag-list">
              {post &&
                post.tags?.nodes?.map((tag: Tag, key) => (
                  <li key={`tag-${key}-${tag?.slug}`}>
                    <a
                      className="btn btn-outline-primary"
                      href={`/the-buzz/tag/${tag?.slug || ""}`}
                    >
                      {tag?.name}
                      <span className="screen-reader-text">
                        {lexemes.tagTItleScreenReader}
                      </span>
                    </a>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </article>
  );
}
