import * as React from "react";

// Search Button
//<button
//.   type="submit"
//.   id="searchsubmit"
//.   className="btn btn-primary search-submit searchtopbtn"
//.   value="Search"
// >
//.   <svg
//.   xmlns="https://www.w3.org/2000/svg"
//.   aria-hidden="true"
//.   role="graphics-symbol"
//.   focusable="false"
//.   viewBox="0 0 512 512"
//.   >
//.   {/*! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc.  */}
//.   <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"></path>
//.   </svg>
//.   Search
//</button>

// Button trigger modal
// <button
//   type="button"
//   className="btn btn-outline-primary"
//   data-bs-toggle="modal"
//   data-bs-target="#search_modal"
// >
//   <svg
//     xmlns="https://www.w3.org/2000/svg"
//     aria-hidden="true"
//     role="graphics-symbol"
//     focusable="false"
//     viewBox="0 0 512 512"
//   >
//     {/*! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc.  */}
//     <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"></path>
//   </svg>{" "}
//   Search
// </button>

type HeaderProps = {
  backgroundColor?: string;
};

export const Header: React.FC<HeaderProps> = (props) => {
  return (
    <div
      className="site-subheader"
      style={{ backgroundColor: props.backgroundColor }}
    >
      <div className="container-xxl">
        <div className="row">
          <div className="col">
            <div className="header-logo">
              <a
                href="/the-buzz"
                className="custom-logo-link"
                rel="home"
                aria-current="page"
              >
                <img
                  width="1170"
                  height="1"
                  src="/the-buzz/logo/buzz-logo.svg"
                  className="custom-logo"
                  alt="Bumble Buzz"
                  decoding="async"
                />
              </a>
              <h1 className="screen-reader-text">Bumble Buzz</h1>
            </div>
          </div>
          {/* .col  */}
          <div className="col header-buttons">
            <div className="d-flex align-items-center justify-content-end header-search-tags">
              <div className="flex-shrink-1 header-search-button">
                {/* Button trigger modal */}
                {/* Modal  */}
                <div
                  className="modal fade"
                  id="search_modal"
                  tabIndex={-1}
                  aria-labelledby="search_modal_label"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                      <form
                        role="search"
                        method="get"
                        id="searchform"
                        className="form-inline search-form"
                        action="/the-buzz"
                      >
                        <div className="modal-header">
                          <p
                            id="search_modal_label"
                            className="screen-reader-text"
                          >
                            Search this site
                          </p>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>

                          <div className="input-group">
                            <h2>
                              <label className="screen-reader-text" htmlFor="s">
                                Search for:
                              </label>
                            </h2>
                            <span className="input-group-text">
                              <svg
                                xmlns="https://www.w3.org/2000/svg"
                                aria-hidden="true"
                                role="graphics-symbol"
                                focusable="false"
                                viewBox="0 0 512 512"
                              >
                                {/*! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc.  */}
                                <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"></path>
                              </svg>
                            </span>

                            {/* <input
                              type="text"
                              name="s"
                              id="s"
                              className="form-control"
                              placeholder="Type keywords here…"
                              value=""
                              title="Search for:"
                            /> */}

                            {/** Search Button */}

                            <div className="modal-body">
                              <h2 id="searchtags">Search by Tags</h2>
                              <ul aria-labelledby="searchtags">
                                <li>
                                  <button
                                    role="button"
                                    type="button"
                                    className="btn btn-outline-primary tag-filter"
                                    data-bs-toggle="button"
                                    aria-label="Bumble Date"
                                    aria-pressed="false"
                                    data-tag-slug="bumble-date"
                                  >
                                    Bumble Date{" "}
                                  </button>
                                </li>
                                <li>
                                  <button
                                    role="button"
                                    type="button"
                                    className="btn btn-outline-primary tag-filter"
                                    data-bs-toggle="button"
                                    aria-label="Dating"
                                    aria-pressed="false"
                                    data-tag-slug="dating"
                                  >
                                    Dating{" "}
                                  </button>
                                </li>
                                <li>
                                  <button
                                    role="button"
                                    type="button"
                                    className="btn btn-outline-primary tag-filter"
                                    data-bs-toggle="button"
                                    aria-label="Success Story"
                                    aria-pressed="false"
                                    data-tag-slug="success-story"
                                  >
                                    Success Story{" "}
                                  </button>
                                </li>
                                <li>
                                  <button
                                    role="button"
                                    type="button"
                                    className="btn btn-outline-primary tag-filter"
                                    data-bs-toggle="button"
                                    aria-label="Bumble Better"
                                    aria-pressed="false"
                                    data-tag-slug="bumble-better"
                                  >
                                    Bumble Better{" "}
                                  </button>
                                </li>
                                <li>
                                  <button
                                    role="button"
                                    type="button"
                                    className="btn btn-outline-primary tag-filter"
                                    data-bs-toggle="button"
                                    aria-label="Bumble HQ"
                                    aria-pressed="false"
                                    data-tag-slug="bumble-hq"
                                  >
                                    Bumble HQ{" "}
                                  </button>
                                </li>
                                <li>
                                  <button
                                    role="button"
                                    type="button"
                                    className="btn btn-outline-primary tag-filter"
                                    data-bs-toggle="button"
                                    aria-label="Astrology"
                                    aria-pressed="false"
                                    data-tag-slug="zodiac"
                                  >
                                    Astrology{" "}
                                  </button>
                                </li>
                                <li>
                                  <button
                                    role="button"
                                    type="button"
                                    className="btn btn-outline-primary tag-filter"
                                    data-bs-toggle="button"
                                    aria-label="Bumble Features"
                                    aria-pressed="false"
                                    data-tag-slug="bumble-features"
                                  >
                                    Bumble Features{" "}
                                  </button>
                                </li>
                                <li>
                                  <button
                                    role="button"
                                    type="button"
                                    className="btn btn-outline-primary tag-filter"
                                    data-bs-toggle="button"
                                    aria-label="Bumble Bizz"
                                    aria-pressed="false"
                                    data-tag-slug="bumble-bizz"
                                  >
                                    Bumble Bizz{" "}
                                  </button>
                                </li>
                                <li>
                                  <button
                                    role="button"
                                    type="button"
                                    className="btn btn-outline-primary tag-filter"
                                    data-bs-toggle="button"
                                    aria-label="Bumble for Friends"
                                    aria-pressed="false"
                                    data-tag-slug="bumble-for-friends"
                                  >
                                    Bumble for Friends{" "}
                                  </button>
                                </li>
                                <li>
                                  <button
                                    role="button"
                                    type="button"
                                    className="btn btn-outline-primary tag-filter"
                                    data-bs-toggle="button"
                                    aria-label="Wellness"
                                    aria-pressed="false"
                                    data-tag-slug="wellness"
                                  >
                                    Wellness{" "}
                                  </button>
                                </li>
                                <li>
                                  <button
                                    role="button"
                                    type="button"
                                    className="btn btn-outline-primary tag-filter"
                                    data-bs-toggle="button"
                                    aria-label="Safety"
                                    aria-pressed="false"
                                    data-tag-slug="safety"
                                  >
                                    Safety{" "}
                                  </button>
                                </li>
                                <li>
                                  <button
                                    role="button"
                                    type="button"
                                    className="btn btn-outline-primary tag-filter"
                                    data-bs-toggle="button"
                                    aria-label="Events"
                                    aria-pressed="false"
                                    data-tag-slug="events"
                                  >
                                    Events{" "}
                                  </button>
                                </li>
                                <li>
                                  <button
                                    role="button"
                                    type="button"
                                    className="btn btn-outline-primary tag-filter"
                                    data-bs-toggle="button"
                                    aria-label="How To"
                                    aria-pressed="false"
                                    data-tag-slug="how-to"
                                  >
                                    How To{" "}
                                  </button>
                                </li>
                                <li>
                                  <button
                                    role="button"
                                    type="button"
                                    className="btn btn-outline-primary tag-filter"
                                    data-bs-toggle="button"
                                    aria-label="Policy"
                                    aria-pressed="false"
                                    data-tag-slug="policy"
                                  >
                                    Policy{" "}
                                  </button>
                                </li>
                                <li>
                                  <button
                                    role="button"
                                    type="button"
                                    className="btn btn-outline-primary tag-filter"
                                    data-bs-toggle="button"
                                    aria-label="Sex and Intimacy"
                                    aria-pressed="false"
                                    data-tag-slug="sex-and-intimacy"
                                  >
                                    Sex and Intimacy{" "}
                                  </button>
                                </li>
                                <li>
                                  <button
                                    role="button"
                                    type="button"
                                    className="btn btn-outline-primary tag-filter"
                                    data-bs-toggle="button"
                                    aria-label="College"
                                    aria-pressed="false"
                                    data-tag-slug="college"
                                  >
                                    College{" "}
                                  </button>
                                </li>
                                <li>
                                  <button
                                    role="button"
                                    type="button"
                                    className="btn btn-outline-primary tag-filter"
                                    data-bs-toggle="button"
                                    aria-label="Bumble BFF"
                                    aria-pressed="false"
                                    data-tag-slug="bumble-bff"
                                  >
                                    Bumble BFF{" "}
                                  </button>
                                </li>
                                <li>
                                  <button
                                    role="button"
                                    type="button"
                                    className="btn btn-outline-primary tag-filter"
                                    data-bs-toggle="button"
                                    aria-label="Bumble Shop"
                                    aria-pressed="false"
                                    data-tag-slug="bumble-shop"
                                  >
                                    Bumble Shop{" "}
                                  </button>
                                </li>
                                <li>
                                  <button
                                    role="button"
                                    type="button"
                                    className="btn btn-outline-primary tag-filter"
                                    data-bs-toggle="button"
                                    aria-label="Mental Health"
                                    aria-pressed="false"
                                    data-tag-slug="mental-health"
                                  >
                                    Mental Health{" "}
                                  </button>
                                </li>
                                <li>
                                  <button
                                    role="button"
                                    type="button"
                                    className="btn btn-outline-primary tag-filter"
                                    data-bs-toggle="button"
                                    aria-label="Reproductive Rights"
                                    aria-pressed="false"
                                    data-tag-slug="reproductive-rights"
                                  >
                                    Reproductive Rights{" "}
                                  </button>
                                </li>
                                <li>
                                  <button
                                    role="button"
                                    type="button"
                                    className="btn btn-outline-primary tag-filter"
                                    data-bs-toggle="button"
                                    aria-label="Trends"
                                    aria-pressed="false"
                                    data-tag-slug="trends"
                                  >
                                    Trends{" "}
                                  </button>
                                </li>
                                <li>
                                  <button
                                    role="button"
                                    type="button"
                                    className="btn btn-outline-primary tag-filter"
                                    data-bs-toggle="button"
                                    aria-label="Career"
                                    aria-pressed="false"
                                    data-tag-slug="career"
                                  >
                                    Career{" "}
                                  </button>
                                </li>
                                <li>
                                  <button
                                    role="button"
                                    type="button"
                                    className="btn btn-outline-primary tag-filter"
                                    data-bs-toggle="button"
                                    aria-label="Bumble Fund"
                                    aria-pressed="false"
                                    data-tag-slug="bumble-fund"
                                  >
                                    Bumble Fund{" "}
                                  </button>
                                </li>
                                <li>
                                  <button
                                    role="button"
                                    type="button"
                                    className="btn btn-outline-primary tag-filter"
                                    data-bs-toggle="button"
                                    aria-label="Horoscope"
                                    aria-pressed="false"
                                    data-tag-slug="horoscope"
                                  >
                                    Horoscope{" "}
                                  </button>
                                </li>
                              </ul>
                            </div>
                            {/* .modal-body  */}
                          </div>
                          {/* .input-group  */}
                        </div>
                        {/* .modal-header  */}

                        <div className="modal-footer">
                          {/* <input
                            type="hidden"
                            name="ep_filter_post_tag"
                            value=""
                            disabled={true}
                          /> */}
                          <button
                            type="submit"
                            id="searchsubmit"
                            className="btn btn-primary search-submit"
                            value="Search"
                          >
                            <svg
                              xmlns="https://www.w3.org/2000/svg"
                              aria-hidden="true"
                              role="graphics-symbol"
                              focusable="false"
                              viewBox="0 0 512 512"
                            >
                              {/*! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc.  */}
                              <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"></path>
                            </svg>
                            Search
                          </button>
                        </div>
                        {/* .modal-footer  */}
                      </form>
                    </div>
                    {/* .modal-content  */}
                  </div>
                  {/* .modal-dialog  */}
                </div>
                {/* .modal  */}
              </div>
              {/* .header-search-button  */}
            </div>
            {/* .header-search-tags  */}
          </div>
          {/* .col.header-buttons  */}
        </div>
        {/* .row  */}
      </div>
      {/* .container-xxl  */}
    </div>
  );
};

export default Header;
